/****------custom-css------***/
body {
  background-color: #f1f3f7 !important;
}
.link-alt {
  color: #31c5f4;
  cursor: pointer;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  background: #fff;
}
table.dataTable
{
  width: calc(100%) !important

}
.otp-message
{
    color: red;
    text-align: center;
    font-weight: 500;
    font-style: italic;
}
.szh-menu
{
  padding-top: 15px !important;
}
.text-align-left
{
  text-align:left;
}
.head-class
{
    color: #31c5f4;
    font-size: 16px;

}
.my-body
{
  padding: 0px !important;
  height: 40px !important;
  margin-top: 15px;
  min-height: 30px !important;
  margin: 0px;
}
.my-row
{
    padding-top: 10px !important;
    text-align: center;
    padding-bottom: 4px !important;
}
.equality
{
  display: flex;
  gap:40px;
}
.label-style
{
    text-align: left;
    width: 100%;
    padding-left: 15px;

}
.imp-white
{
  background-color: white !important;
}

.remove-margin
{
  margin-bottom: 10px !important;
}
.custom-radios
{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.supplement-popup
{
    border-radius: 20px;
    width: 100px;
    z-index: 99999;
    margin-right: 40px;    
    height: 100px;
    background-color: white;
    position: fixed;
}
.padding-add
{
  padding-right: 70px !important;
}

.custom_select-div .select__menu
{
  z-index: 99999 !important; 
}

@media screen and (max-width: 40em) {
    table.dataTable tbody tr
    {
        padding-bottom: 20px !important;
    }
}

.dropdown-menu.fromtop {
  top: -15px;
  transform: translateY(-100%);
}
.dropdown-menu-arrow.dropdown-menu-right.fromtop:after,
.dropdown-menu-arrow.dropdown-menu-right.fromtop:before {
  top: auto;
  bottom: -5px;
  transform: rotate(180deg);
}
.breadArrows {
  margin: 0 0 0 0.5rem;
  color: #adb5bd;
  font-weight: 900;
}
.star_ratings-wrap {
  display: inline-block;
}
.star_ratings {
  color: #31c5f4;
  margin: 0 1px;
  font-size: 15px;
}
.star_ratings.empty {
  color: #ccc;
}
.readmore.collapsable {
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 500px;
}
.readmore.height_auto {
  max-height: 2500px;
}
.default-color {
  color: #31c5f4;
}
/* .modal-body {
  max-height: calc(100% - 276px);
} */
[data-tip] {
  cursor: pointer;
}
[class*="dlist-"] {
  margin-bottom: 10px;
}
.share_event {
  overflow: hidden;
  padding: 0;
  margin: 10px 0 0;
}
.share_event > li {
  float: left;
  margin-right: 20px;
  list-style-type: none;
}
.share_event > li a {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 50px;
  color: #fff;
  background: #31c5f4;
}
.share_event > li a.facebook {
  background: #3d548e;
}
.share_event > li a.twitter {
  background: #4c9be5;
}
.share_event > li a.linkedin {
  background: #0c61bf;
}
.share_event > li a span {
  font-size: 12px;
  margin-left: 10px;
}
.share_event > li a:hover {
  background: #31c5f4;
}
.ticketDivdr {
  margin: 0 0 20px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
}
.modal-body > .ticketDivdr:only-child,
.modal-body > .ticketDivdr:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}
.close:focus {
  outline: none;
}
.custom_calendar .fc .fc-toolbar {
  justify-content: center;
}
.custom_calendar .fc-today-button {
  display: none !important;
}
.custom_calendar .fc-header-toolbar .fc-toolbar-title {
  font-size: 15px;
}
.custom_calendar .fc-header-toolbar {
  position: relative;
}
.custom_calendar .fc-header-toolbar .fc-toolbar-chunk > .fc-button-group {
  position: static;
}
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-prev-button,
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px;
  border-radius: 3px;
  font-size: 12px;
}
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-prev-button {
  left: 10px;
}
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-next-button {
  right: 10px;
}
.custommodal.modal-dialog-scrollable
{
  margin: 2px !important;
  max-height: 99%;
}
.custommodalsecond.modal-dialog-scrollable
{
  margin: 2px !important;
  max-height: 99%;
}
.custommodal .close
{
  display: none !important;
}
.custommodal .modal-footer > :first-child
{
  display: none !important;
}
.warning
{
  color: red;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  font-style: italic;
  font-size: 12px;
}
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-prev-button:hover,
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-next-button:hover {
  background: #31c5f4;
  border-color: #31c5f4;
}
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-prev-button:focus,
.custom_calendar
  .fc-header-toolbar
  .fc-toolbar-chunk
  > .fc-button-group
  > .fc-next-button:focus {
  box-shadow: none;
}
.custom_calendar .fc-theme-standard td,
.custom_calendar .fc-theme-standard th,
.custom_calendar .fc .fc-scrollgrid {
  border: 0 !important;
}
.custom_calendar .fc .fc-daygrid-day-top {
  justify-content: center;
}
.custom_calendar .fc .fc-daygrid-day.fc-day-today {
  background: #f1f3f7;
  color: #333;
}
.custom_calendar .fc-h-event .fc-event-title {
  display: block;
  text-align: center;
  cursor: pointer;
}
.cart_item {
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}
[class*="dlist-"].small {
  font-size: 12px !important;
}
.media-body > [class*="dlist-"]:last-child {
  margin-bottom: 0;
}
.cart_items-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #dee2e6;
}
@media only screen and (max-width: 767px) {
  .cart_item-section .img-wrap {
    margin-bottom: 10px !important;
  }
  .cart_items-footer .btn {
    font-size: 12px;
    padding: 8px 12px;
    margin: 0 0 10px 10px !important;
  }
  .cart_items-footer {
    padding-bottom: 0;
  }
}
.subitems > li {
  padding-left: 30px;
}
.divdes
{
  
  padding-left: 0px;
  display: flex;
  align-items: center;

}
.rowbut2
{
    height: 45px !important;
    border-radius: 5px;
    background-color: red;
    color: white;
    padding-bottom: 2px;
    padding-top: 2px;
    font-size: 20px;
    height: auto;
}
.listss {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
}

.itemd {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(84, 84, 241);
  color: white;
  height: 150px;
  cursor: grab;
  font-size: 20px;
  user-select: none;
}
.item_img
{
  cursor: grab;
  user-select: none;
  background-size: cover !important;
  height: 250px ;
}
.dragged {
  box-shadow:"0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)"
}
.big-font
{
font-size: 1rem;
}
.rm-margin
{
  margin-left: 0px !important;
}
.voucher-img
{
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.rowbut
{
    height: 45px !important;
    border-radius: 5px;
    background-color: #31c5f4;
    color: white;
    padding-bottom: 2px;
    padding-top: 2px;
    font-size: 20px;
    height: auto;
}
.custom_select-div .select__control {
  height: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #8898aa;
  border: 1px solid #f1f3f7;
  border-radius: 0.25rem;
  background-color: #f1f3f7;
}
.pay-down
{
    text-align: center;
    font-weight: bold;
    color: red;
    font-size: 20px;
}
.customdownload:hover {
  color: #b45307
  !important;
}
.react-datepicker-popper
{
  z-index: 999999 !important;
}
.center
{
  text-align: center;
}
.background-color-new
{
background-color: #31c5f4!important;
justify-content: center;
display: flex;
min-height: 100vh;
align-items: center;
}
.card-color-back
{
text-align: center;
font-size: 25px;
color: red;
}
.custom_select-div .select__control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #69d7fa;
  outline: 0;
}
.custom_select-div.select__control--isfocused {
  outline: 0;
  box-shadow: none;
  border-color: 0;
}
.no-arrow::after {
  content: none;
}
.select_white-bg .custom_select-div .select__control {
  background: #fff;
}
.select_white-bg .form-group {
  margin: 0 !important;
}
.gallery_collection {
  border: 1px solid #f1f3f7;
  padding: 2em;
  margin-bottom: 1.5rem;
}
/* ---react---tabs---- */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #f1f3f7;
  margin: 0;
  padding: 0;
}
.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 1rem;
  cursor: pointer;
  color: #31c5f4;
}
.react-tabs__tab--selected {
  background: #fff;
  border-color: #f1f3f7;
  color: black;
  border-radius: 5px 5px 0 0;
}
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}
.react-tabs__tab-panel {
  display: none;
  padding: 20px;
  border: 1px solid #f1f3f7;
  border-top: none;
}
.react-tabs__tab-panel--selected {
  display: block;
}
/* ------ */
.table .thead-light th {
  background: #31c5f4;
  color: #fff;
  border: 0;
}
.custom-table.table td,
.custom-table.table th {
  padding: 10px;
  vertical-align: middle;
}
.filter-content .form-group {
  margin-bottom: 0 !important;
}
.noUi-touch-area:focus,
.noUi-touch-area {
  outline: none;
}
.z-4 {
  z-index: 4;
}
.z-3 {
  z-index: 3;
}
.z-2 {
  z-index: 2;
}
.react-datepicker-wrapper{
  width: 100%;
}
.merchandise_table-img{
  max-width: 120px;
}
@media only screen and (max-width:992px){
 .custom_dropdown_new .dropdown-menu-arrow:before{
    content:none;
  }
  .dropdown-toggle.custom_dropdown_new:after{
    position:absolute;
    top:25px;
    left:70px;
  }
}
@media only screen and (min-width:993px){
  .sign_up-pdng{
      padding:10px;
  }
}
.event-details-location1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
}
.event-description img {
  max-width: 100%;
  height: auto;
}